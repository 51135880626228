
@tailwind base;
@tailwind components;
@tailwind utilities;



/* Hide breadcrumbs on small screens */
@media (max-width: 768px) {
  .breadcrumbs {
    display: none;
  }

  /* Move sidebar to the top on small screens */
  .side-navbar {
    flex-direction: column;
  }

  .side-navbar .sidebar {
    width: 100%;
    position: relative;
    top: 0;
  }
}


/* Spinner animation */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 2s linear infinite;
}

/* Profile information- Date picker */

/* customStyles.css */
@media (min-width: 768px) {
  .MuiFormControl-root.MuiTextField-root {
    @apply mt-10 -left-24 rounded;
  }
}

@media (max-width: 767px) {
  .MuiFormControl-root.MuiTextField-root {
    @apply mt-0 left-0 rounded-none;
  }
}

.MuiInputBase-input.MuiOutlinedInput-input {
  @apply text-black;
}
/* For the icon color */
.MuiSvgIcon-root {
  @apply text-gray-400;
}

/* For the border color */
.MuiOutlinedInput-notchedOutline {
  @apply border-gray-300;
}

/* Add more styles as needed */

@layer base {
  :root {
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;

    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;

    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;

    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;

    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;

    --radius: 0.5rem;
  }

  .text-gold-gradient {
    background: linear-gradient(270deg, #d28d0d, #fdf68c, #f4cd2a, #eda323, #d28d0d);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline; /* Ensure gradients apply to inline elements like text */
  }
  
}